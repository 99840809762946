export function redirectToQueryParam(router) {
  const redirect = router.queryParams?.redirect;
  if (redirect) {
    window.location = decodeURIComponent(redirect);
  }
}

export function redirectToExplorer(router, views) {
  router.goTo(views.explorer, {});
}
