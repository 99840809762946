import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import {LoginWrapper, Caption, SignInView, TermsConditionsLabel, TermsConditionsLink, CantViewContent} from './styles';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import CenteredMessage from 'components/Layout/CenteredMessage';

import {termsAndConditionsUrl} from 'shared/constants';

import messages from './messages';

@inject('store')
@observer
class SignInViewComponent extends Component {
  render() {
    const {
      store: {
        auth: {loggedInButCantViewContent, user}
      }
    } = this.props;

    const loginContent = loggedInButCantViewContent ? (
      <CantViewContent>
        <FormattedMessage {...messages.message_cantViewContent} />
      </CantViewContent>
    ) : (
      <React.Fragment>
        <Caption>
          <FormattedMessage {...messages.signInToYourWorkspace} />
        </Caption>
        {this.props.children}
        <TermsConditionsLabel>
          <FormattedMessage
            {...messages.messages_byUsingOurService}
            values={{
              termsAndPolicyLink: (
                <TermsConditionsLink href={termsAndConditionsUrl} target="_blank">
                  <FormattedMessage {...messages.termsAndConditionsAndPrivacyPolicy} />
                </TermsConditionsLink>
              )
            }}
          />
        </TermsConditionsLabel>
      </React.Fragment>
    );

    return (
      <SignInView>
        {user ? (
          <CenteredMessage icon={'user'}>
            <FormattedMessage {...messages.message_youAreAlreadyLoggedIn} />
          </CenteredMessage>
        ) : (
          <LoginWrapper>{loginContent}</LoginWrapper>
        )}
      </SignInView>
    );
  }
}

export default SignInViewComponent;
